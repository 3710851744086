export default {
    step1: {
        submit: "Step1.Submit"
    },
    step2: {
        submit: "Step2.Submit"
    },
    step3: {
        submit: "Step3.Submit"
    },
    step4: {
        submit: "Step4.Submit"
    },
    step5: {
        submit: "Step5.Submit"
    }
}